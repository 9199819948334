import React from 'react'
import {Link} from 'gatsby'

export default ({projects, title}) => (
  <div>
    <div className="grid-container contained">
      <div className="row pt3 pb3">
        <div className="col c12">
          <h2 className="sans--xs uppercase fw--500">{title}</h2>
        </div>
      </div>
    </div>

    <hr />
    {projects.map(
      ({title, hoverImage, shortDescription, cardImage, slug, _key}) => (
        <React.Fragment key={_key}>
          <Link
            to={`/projects/${slug}`}
            key={_key}
            className="db project-list__item pr"
          >
            <div className="grid-container contained">
              <div className="row pt2 pb2">
                <div className="col c3--md">
                  <h3 className="sans--sm sans--md--md dib">{title}</h3>
                </div>
                <div className="col c9--md show--md">
                  <p className="sans--sm sans--md--md dib">
                    {shortDescription}
                  </p>
                </div>
              </div>
            </div>
            {hoverImage && (
              <img
                className="pa right project-list__hover-image z2"
                alt={hoverImage.alt}
                src={`${hoverImage.url}?w=680&auto=format&q=75`}
              />
            )}
          </Link>
          <hr />
        </React.Fragment>
      ),
    )}
  </div>
)
