import React from 'react'
// import {Link} from 'gatsby'
import Seo from '../components/Seo'
import ProjectList from '../components/ProjectList'
import SanityMuxPlayer from 'sanity-mux-player'
import Footer from '../components/Footer'
import Link from '../components/Link'

export default ({pageContext, location}) => {
  const {
    header,
    title,
    backgroundColor,
    textColor,
    client,
    projectType,
    longDescription,
    images,
    projectList,
    footer,
    link,
  } = pageContext

  const metaTitle = `${pageContext.title} - Katherine Pihl`

  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
    >
      <Seo
        metaTitle={metaTitle}
        metaDescription={longDescription}
        metaKeywords={`${client} ${projectType} ${longDescription}`}
        openGraphTitle={metaTitle}
        openGraphDescription={longDescription}
        openGraphImage={images[0].url}
        twitterTitle={metaTitle}
        twitterDescription={longDescription}
        twitterImage={images[0].url}
        pathname={location.pathname}
      />
      <div className="grid-container contained"
      >
        <div className="df fdc jcb ais mb4--md">
          <div className="df fdc">
            <header className="mb19--md mb11 mt5">
              <Link url="/" className="hover no-focus">
                <h1 className="serif--md serif--lg--md">{header.title}</h1>
              </Link>
            </header>
          </div>
          <div className="row x">
            <div className="col c9--md c12 mb4 mb0--md">
              <h2 className="sans--xs uppercase fw--500 mb2">{title}</h2>
              <p className="serif--md serif--lg--md">{longDescription}</p>
            </div>

            {/* mobile */}
            {client && (
              <div className="col c6 hide--md">
                <div className="mb5">
                  <p className="mb2 sans--xs fw--500 uppercase">With</p>
                  <p className="sans--sm">{client}</p>
                </div>
              </div>
            )}
            <div className="col c6 hide--md">
              <p className="mb2 sans--xs fw--500 uppercase">Role</p>
              {projectType.split(', ').map(t => (
                <p className="sans--sm mb1" key={t}>
                  {t}
                </p>
              ))}
            </div>
            <div className="col c12 mb6 mt2 hide--md">
              {link && (
                <Link
                  {...link}
                  className="mb2 sans--xs fw--500 uppercase underline no-focus link--opacity"
                >
                  {'View website'}
                </Link>
              )}
            </div>

            {/* desktop */}
            <div className="col c1--md show--md" />

            <div className="col c2--md show--md">
              {client && (
                <div className="mb5">
                  <p className="mb2 sans--xs fw--500 uppercase">With</p>
                  <p className="sans--md">{client}</p>
                </div>
              )}
              <div className="mb5">
                <p className="mb2 sans--xs fw--500 uppercase">Role</p>
                {projectType.split(', ').map(t => (
                  <p className="sans--md mb1">{t}</p>
                ))}
              </div>
              {link && (
                <div>
                  <Link
                    {...link}
                    className="mb2 sans--xs fw--500 uppercase underline no-focus link--opacity"
                  >
                    {'View website'}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container contained flush padded--md mb11--md mb6">
        <AssetList assets={images} />
      </div>
      <div className="mt15 mb9">
        <ProjectList
          title={projectList.title}
          projects={projectList.projects}
        />
      </div>
      <div className="grid-container contained">
        <div className="row">
          <div className="col c12 c8--md">
            <Footer copy={footer.copy} />
          </div>
        </div>
      </div>
    </div>
  )
}

const AssetList = ({assets}) => (
  <>
    {assets.map((asset, i) => {
      switch (asset._type) {
        case 'image':
          return (
            <div className="pt6--md pt3" key={`project-image-${i}`}>
              <div className="image size--16x9">
                <picture>
                  <source
                    srcSet={`${asset.url}?w=2000&auto=format&q=75`}
                    media="(min-width: 1000px)"
                  />
                  <source
                    srcSet={`${asset.url}?w=1000&auto=format&q=75`}
                    media="(min-width: 400px)"
                  />
                  <img
                    alt={asset.alt}
                    src={`${asset.url}?w=680&auto=format&q=75`}
                  />
                </picture>
              </div>
            </div>
          )
        case 'video':
          return (
            <div className="pt6--md pt3" key={`project-image-${i}`}>
              <SanityMuxPlayer
                assetDocument={asset.asset}
                className="split-page-video"
                autoload
                autoplay
                loop
                showControls={false}
                data-keepplaying
              />
            </div>
          )
        default:
          return null
      }
    })}
  </>
)
